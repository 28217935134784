import React, { useState, useEffect } from "react";
import socket from "./socket";
import Map from "./components/Map";
import PlayerList from "./components/PlayerList";
import KingdomPopup from "./components/KingdomPopup";
import { useWallet } from "./utils/WalletContext";
import ProtectedComponent from "./components/ProtectedComponent";
import "./App.css";

function App() {
  const { isConnected, publicKey } = useWallet();
  const [kingdoms, setKingdoms] = useState([]);
  const [selectedKingdom, setSelectedKingdom] = useState(null);
  const [warStates, setWarStates] = useState({});

  useEffect(() => {
    if (isConnected && publicKey.unformatted) {
      if (!socket.connected) {
        socket.connect();
      }

      // Register all event listeners
      const setupEventListeners = () => {
        socket.on("connect", () => {
          console.log("WebSocket Connected");
          socket.emit("registerAddress", publicKey);
          socket.emit("readyForData");
        });

        socket.on("initialize", (updatedKingdoms) => {
          setKingdoms(updatedKingdoms);
          const newWarStates = {};
          updatedKingdoms.forEach((k) => {
            newWarStates[k._id] = k.warActive;
          });
          setWarStates(newWarStates);
        });

        socket.on("kingdomsUpdated", (updatedKingdoms) => {
          setKingdoms((prevKingdoms) =>
            mergeUpdatedKingdoms(prevKingdoms, updatedKingdoms)
          );
        });

        socket.on("updatePlayerList", (playerList) => {
          console.log("Player List Updated:", playerList);
        });
        socket.on("kingdomLocked", ({ kingdomId, locked }) => {
          console.log(`Kingdom ${kingdomId} has been locked: ${locked}`);
          setKingdoms((prevKingdoms) =>
            prevKingdoms.map((kingdom) =>
              kingdom._id === kingdomId
                ? { ...kingdom, isLocked: locked, isPurchasing: true }
                : kingdom
            )
          );
        });

        socket.on("kingdomUnlocked", ({ kingdomId, locked }) => {
          setKingdoms((prevKingdoms) =>
            prevKingdoms.map((kingdom) =>
              kingdom._id === kingdomId
                ? { ...kingdom, isLocked: locked, isPurchasing: false }
                : kingdom
            )
          );
        });

        socket.on("warInitiated", ({ kingdomId, locked, warActive }) => {
          setWarStates((prev) => ({ ...prev, [kingdomId]: warActive }));
        });

        socket.on(
          "warProgressUpdated",
          ({ kingdomId, warProgress, warActive, isLocked }) => {
            setKingdoms((prevKingdoms) =>
              prevKingdoms.map((kingdom) =>
                kingdom._id === kingdomId
                  ? { ...kingdom, warProgress, warActive, isLocked }
                  : kingdom
              )
            );
          }
        );

        socket.on(
          "warEnded",
          ({ kingdomId, newOwner, warActive, isLocked }) => {
            setKingdoms((prevKingdoms) =>
              prevKingdoms.map((kingdom) =>
                kingdom._id === kingdomId
                  ? {
                      ...kingdom,
                      ownerPublicKey: newOwner,
                      warActive,
                      isLocked,
                    }
                  : kingdom
              )
            );
          }
        );
      };

      setupEventListeners();

      // Cleanup function to remove all event listeners
      return () => {
        socket.off("connect");
        socket.off("initialize");
        socket.off("kingdomsUpdated");
        socket.off("updatePlayerList");
        socket.off("kingdomLocked");
        socket.off("kingdomUnlocked");
        socket.off("warInitiated");
        socket.off("warProgressUpdated");
        socket.off("warEnded");
        socket.disconnect();
      };
    }
  }, [isConnected, publicKey.unformatted]); // Only re-run effects if these values change

  useEffect(() => {
    if (selectedKingdom) {
      // Update selectedKingdom when kingdoms change
      const updatedKingdom = kingdoms.find(
        (k) => k._id === selectedKingdom._id
      );
      setSelectedKingdom(updatedKingdom || null); // Set to null if not found
    }
  }, [kingdoms, selectedKingdom]);

  const handleSelectKingdom = (kingdom) => {
    console.log("Selected Kingdom:", kingdom.name);
    const freshKingdom = kingdoms.find((k) => k._id === kingdom._id) || kingdom;
    setSelectedKingdom(freshKingdom);
  };

  const mergeUpdatedKingdoms = (prevKingdoms, updatedKingdoms) => {
    const mergedKingdoms = [...prevKingdoms];
    updatedKingdoms.forEach((updatedKingdom) => {
      const index = mergedKingdoms.findIndex(
        (k) => k._id === updatedKingdom._id
      );
      if (index !== -1) {
        mergedKingdoms[index] = updatedKingdom;
      } else {
        mergedKingdoms.push(updatedKingdom);
      }
    });
    return mergedKingdoms;
  };

  const handleKingdomUpdate = (updatedKingdom) => {
    setKingdoms((prevKingdoms) => {
      return prevKingdoms.map((kingdom) => {
        if (kingdom._id === updatedKingdom._id) {
          return updatedKingdom; // Update the specific kingdom with new data
        }
        return kingdom;
      });
    });
  };

  return (
    <div className="App">
      {isConnected ? (
        <>
          <Map
            kingdoms={kingdoms}
            onSelectKingdom={handleSelectKingdom}
            selectedKingdom={selectedKingdom}
          />
          <KingdomPopup
            kingdom={selectedKingdom}
            onClose={() => setSelectedKingdom(null)}
            warStarted={warStates[selectedKingdom?._id] || false}
            setWarStarted={(state) =>
              setWarStates((prev) => ({
                ...prev,
                [selectedKingdom._id]: state,
              }))
            }
            onKingdomUpdate={handleKingdomUpdate}
          />
          <PlayerList />
        </>
      ) : (
        <ProtectedComponent />
      )}
    </div>
  );
}

export default App;
