// src/socket.js
import io from "socket.io-client";

// Create a module-scoped instance so that it persists across imports
const socket = io("https://ws.cassol.live", {
  autoConnect: false, // Prevent automatic connection
  // https://ws.cassol.live
  // localhost:3000
});

export default socket;
