import React, { useState } from "react";
import { useWallet } from "../utils/WalletContext";

const ConnectWalletButton = () => {
  const { isConnected, provider, setPublicKey, setIsConnected } = useWallet();
  const [error, setError] = useState(""); // State to hold any error messages

  const connectWallet = async () => {
    try {
      if (provider) {
        const response = await provider.connect();
        setIsConnected(true);
        setPublicKey(response.publicKey.toString());
        setError(""); // Clear any existing errors on successful connection
      } else {
        throw new Error("Wallet provider not found.");
      }
    } catch (error) {
      console.error("Failed to connect to the wallet:", error);
      setError(
        "Failed to connect to the wallet. Please make sure Phantom Wallet is installed and try again."
      );
    }
  };

  return (
    <div>
      {isConnected ? (
        <p>Wallet connected: {provider.publicKey.toString()}</p>
      ) : (
        <>
          <button onClick={connectWallet}>Connect Wallet</button>
          {error && <p>{error}</p>}
        </>
      )}
    </div>
  );
};

export default ConnectWalletButton;
