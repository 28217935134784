import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";

const getProvider = () => {
  if (typeof window !== "undefined" && "solana" in window) {
    const provider = window.solana;
    if (provider.isPhantom) {
      return provider;
    } else {
      alert("Phantom wallet not found!");
    }
  } else {
    alert("Solana object not found! Please install Phantom Wallet.");
  }
  return null;
};

const connectWallet = async (provider) => {
  try {
    if (!provider.isConnected) {
      await provider.connect();
      console.log("Wallet is connected:", provider.publicKey.toString());
    }
  } catch (error) {
    console.error("Failed to connect to the wallet:", error);
    throw new Error("Connection failed");
  }
};

const isValidPublicKey = (key) => {
  try {
    new PublicKey(key);
    return true;
  } catch (error) {
    console.error("Invalid public key:", error);
    return false;
  }
};

// Convert lamports to SOL
function lamportsToSol(lamports) {
  return lamports / 1_000_000_000; // 1 SOL = 1 billion lamports
}

export { getProvider, connectWallet, isValidPublicKey, lamportsToSol };
