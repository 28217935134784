import React, { useEffect, useState } from "react";
import { useWallet } from "../utils/WalletContext";
import { purchaseProperty, TRANSACTION_TYPE } from "../utils/transactions";
import { isValidPublicKey } from "../utils/solanaWallet";
import socket from "../socket";
import WageWar from "./WageWar";

const KingdomPopup = ({
  kingdom,
  onClose,
  warStarted,
  setWarStarted,
  onKingdomUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { publicKey } = useWallet();

  useEffect(() => {
    console.log("Kingdom data updated in popup:", kingdom);
  }, [kingdom]);

  if (!kingdom) {
    return <div>Loading...</div>;
  }

  const handlePurchase = async () => {
    if (!isValidPublicKey(publicKey.unformatted)) {
      alert("Invalid public key for transaction.");
      return;
    }

    setIsLoading(true);
    try {
      socket.emit("lockKingdom", {
        kingdomId: kingdom._id,
        ownerPublicKey: publicKey.unformatted,
      });
      const lamports = kingdom.price * 1000000000;
      const purchaseResult = await purchaseProperty(
        kingdom._id,
        publicKey.unformatted,
        lamports,
        TRANSACTION_TYPE.PURCHASE
      );
      if (purchaseResult.success) {
        const updatedKingdom = {
          ...kingdom,
          ownerPublicKey: publicKey.unformatted,
        };
        onKingdomUpdate(updatedKingdom);
        setWarStarted(false);
      } else {
        throw new Error("Purchase transaction failed at the server.");
      }
    } catch (error) {
      console.error("Purchase failed:", error);
      alert("Purchase failed: " + error.message);
      socket.emit("unlockKingdom", { kingdomId: kingdom._id });
    } finally {
      setIsLoading(false);
    }
  };

  const isOwnedByUser = kingdom.ownerPublicKey === publicKey.unformatted;
  const canBuy = !kingdom.isLocked && !kingdom.ownerPublicKey;
  const canStartWar = kingdom.isLocked && !isOwnedByUser && !isLoading;

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>{kingdom.name}</h2>
        <img
          src={kingdom.image}
          alt={kingdom.name}
          style={{ width: "100px" }}
        />
        <p>{kingdom.description}</p>
        <p>Price: {kingdom.price} SOL</p>
        <p>Current Owner: {kingdom.ownerPublicKey || "No owner"}</p>
        {isOwnedByUser ? (
          <p>You own this kingdom.</p>
        ) : isLoading ? (
          <p>Processing a buy...</p>
        ) : canBuy ? (
          <button onClick={handlePurchase}>Buy Kingdom</button>
        ) : canStartWar ? (
          <WageWar
            kingdomId={kingdom._id}
            warStarted={warStarted}
            setWarStarted={setWarStarted}
            warProgress={kingdom.warProgress}
            tokensRequired={kingdom.tokensRequired}
          />
        ) : (
          <p>Unable to purchase at this moment.</p>
        )}
        <button onClick={onClose}>Close</button>
        {kingdom.warActive && (
          <div className="war-bar">
            <div
              style={{
                height: "20px",
                width: `${
                  (kingdom.warProgress / kingdom.tokensRequired) * 100
                }%`,
                backgroundColor: "red",
              }}
            ></div>
            <p>
              War Progress:{" "}
              {Math.round((kingdom.warProgress / kingdom.tokensRequired) * 100)}
              %
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default KingdomPopup;
