import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import {
  getProvider,
  connectWallet,
  isValidPublicKey,
  lamportsToSol,
} from "./solanaWallet";
import socket from "../socket";
import config from "./config"; // Assume config.js exports your settings

const TRANSACTION_TYPE = {
  PURCHASE: "purchase",
  WAR_CONTRIBUTION: "war_contribution",
};

const connection = new Connection(config.rpcUrl, "confirmed");

const purchaseProperty = async (
  kingdomId,
  fromPubkey,
  amount,
  transactionType = TRANSACTION_TYPE.PURCHASE
) => {
  if (!isValidPublicKey(fromPubkey) || amount <= 0) {
    throw new Error("Invalid parameters for the transaction.");
  }

  const provider = getProvider();
  if (!provider) throw new Error("Wallet provider not found");
  await connectWallet(provider);

  const fromPublicKey = new PublicKey(fromPubkey);
  const toPublicKey = new PublicKey(
    transactionType === TRANSACTION_TYPE.PURCHASE
      ? config.ownershipTransferAddress
      : config.warContributionAddress
  );

  const transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: fromPublicKey,
      toPubkey: toPublicKey,
      lamports: amount,
    })
  );

  try {
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = provider.publicKey;

    const { signature } = await provider.signAndSendTransaction(transaction);
    const confirmationResult = await connection.confirmTransaction(
      signature,
      "finalized"
    );

    if (confirmationResult.value.err === null) {
      console.log("Transaction successful with signature:", signature);
      handleTransactionSuccess(kingdomId, fromPubkey, transactionType, amount);
      return { success: true, message: "Transaction successful", signature };
    } else {
      throw new Error(`Transaction failed: ${confirmationResult.value.err}`);
    }
  } catch (error) {
    console.error("Error during the transaction:", error.message);
    throw error; // Rethrow to handle it in the UI
  }
};

function handleTransactionSuccess(
  kingdomId,
  fromPubkey,
  transactionType,
  amount
) {
  if (transactionType === TRANSACTION_TYPE.PURCHASE) {
    socket.emit("updateOwnership", { kingdomId, ownerPublicKey: fromPubkey });
    console.log("Ownership updated for kingdom ID:", kingdomId);
  } else {
    socket.emit("wageWar", { kingdomId, tokens: lamportsToSol(amount) });
    console.log("War contribution recorded for kingdom ID:", kingdomId);
  }
}

export { purchaseProperty, TRANSACTION_TYPE };
