import React from "react";
import { useWallet } from "../utils/WalletContext";
import ConnectWalletButton from "./walletConnectButton";
import castleLogo from "../assets/cas-sol-logo.png";

const ProtectedComponent = () => {
  const { isConnected } = useWallet();

  if (!isConnected) {
    return (
      <div className="container">
        <div className="box">
          <img src={castleLogo} alt="Castle Logo" />
          <div className="text-container">
            <h2>How To Play</h2>
            <p>1. Connect Wallet (same wallet that has tokens)</p>
            <p>2. Purchase a kingdom</p>
            <p>3. Interact with your kingdom</p>
          </div>
          <ConnectWalletButton />
        </div>
      </div>
    );
  }

  return (
    <div>This is a protected component that requires wallet connection.</div>
  );
};

export default ProtectedComponent;
