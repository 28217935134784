import React, { useState, useEffect } from "react";
import socket from "../socket"; // Ensure you import your socket instance correctly

const PlayerList = () => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    socket.on("updatePlayerList", (updatedPlayers) => {
      console.log("Player List:", updatedPlayers);
      setPlayers(updatedPlayers); // Update the player list state
    });

    return () => {
      socket.off("updatePlayerList"); // Cleanup the listener when the component unmounts
    };
  }, []);

  return (
    <div className="player-list">
      <h2>Connected Players</h2>
      <ul>
        {players.map((player, index) => (
          <li key={index}>
            <a
              href={`https://solscan.io/account/${player.unformatted}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {player.formatted}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayerList;
