import React from "react";

const KingdomMarker = ({ kingdom, onSelectKingdom }) => {
  return (
    <div
      className="kingdom-marker"
      style={{
        left: `${kingdom.coords.x}%`,
        top: `${kingdom.coords.y}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
      }}
      onClick={() => onSelectKingdom(kingdom)}
    >
      <img src={kingdom.image} alt={kingdom.name} style={{ width: 100 }} />
      <h3>{kingdom.name}</h3>
      <p>Owned by: {kingdom.ownerPublicKey || "No owner"}</p>
      {kingdom.warActive && (
        <div
          style={{
            width: "100px",
            height: "10px",
            backgroundColor: "grey",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: `${(kingdom.warProgress / kingdom.tokensRequired) * 100}%`,
              height: "10px",
              backgroundColor: "red",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KingdomMarker;
