import React, { useState, useRef } from "react";
import KingdomMarker from "./KingdomMarker"; // Ensure to import the new component

const Map = ({ kingdoms, onSelectKingdom }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);

  const mapWidth = 2000;
  const mapHeight = 2000;

  const startPan = (event) => {
    const startX = event.clientX;
    const startY = event.clientY;
    const startPos = { ...position };

    const doPan = (moveEvent) => {
      const newX = startPos.x + (moveEvent.clientX - startX);
      const newY = startPos.y + (moveEvent.clientY - startY);

      // Boundary checks to keep the map within the visible area
      const maxX = 0;
      const maxY = 0;
      const minX = -(mapWidth - window.innerWidth);
      const minY = -(mapHeight - window.innerHeight);

      setPosition({
        x: Math.min(maxX, Math.max(newX, minX)),
        y: Math.min(maxY, Math.max(newY, minY)),
      });
    };

    const stopPan = () => {
      window.removeEventListener("mousemove", doPan);
      window.removeEventListener("mouseup", stopPan);
    };

    window.addEventListener("mousemove", doPan);
    window.addEventListener("mouseup", stopPan);
  };

  return (
    <div
      ref={mapRef}
      className="map-container"
      onMouseDown={startPan}
      style={{ cursor: "grab" }}
    >
      <div
        className="map"
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
          width: `${mapWidth}px`,
          height: `${mapHeight}px`,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {kingdoms.map((kingdom) => (
          <KingdomMarker
            key={kingdom.id}
            kingdom={kingdom}
            onSelectKingdom={onSelectKingdom}
          />
        ))}
      </div>
    </div>
  );
};

export default Map;
