import React, { useState, useEffect } from "react";
import socket from "../socket";
import { useWallet } from "../utils/WalletContext";
import { purchaseProperty, TRANSACTION_TYPE } from "../utils/transactions";
import { isValidPublicKey } from "../utils/solanaWallet";

const WageWar = ({
  kingdomId,
  warStarted,
  setWarStarted,
  warProgress,
  tokensRequired,
}) => {
  const [tokens, setTokens] = useState(0);
  const { publicKey } = useWallet();
  const [isLoading, setIsLoading] = useState(false);

  const startWar = () => {
    if (!isValidPublicKey(publicKey.unformatted)) {
      alert("Invalid public key");
      return;
    }
    setWarStarted(true);
    socket.emit("startWar", {
      kingdomId,
      userPublicKey: publicKey.unformatted,
    });
  };

  const handleWageWar = async () => {
    if (tokens > 0 && isValidPublicKey(publicKey.unformatted)) {
      setIsLoading(true);
      const lamports = Math.round(tokens * 1_000_000_000); // Ensure conversion is explicitly a number and rounded if necessary

      try {
        await purchaseProperty(
          kingdomId,
          publicKey.unformatted,
          lamports,
          TRANSACTION_TYPE.WAR_CONTRIBUTION
        );
        setTokens(0); // Reset token count after sending
      } catch (error) {
        console.error("War contribution failed:", error.message);
        alert("War contribution failed: " + error.message);
      } finally {
        setIsLoading(false);
      }
    } else if (tokens <= 0) {
      alert("Please enter a valid amount of tokens.");
    }
  };

  return (
    <div className="war">
      {warStarted ? (
        <>
          <input
            type="number"
            value={tokens}
            onChange={(e) => setTokens(Number(e.target.value))}
            placeholder="Enter tokens"
            disabled={isLoading}
          />
          <button onClick={handleWageWar} disabled={!tokens || isLoading}>
            Contribute to War
          </button>
          <div className="progress-bar">
            <p>Total Needed to Win: {tokensRequired} Tokens</p>
          </div>
        </>
      ) : (
        <button
          onClick={startWar}
          disabled={isLoading || !isValidPublicKey(publicKey.unformatted)}
        >
          Start War
        </button>
      )}
    </div>
  );
};

export default WageWar;
