import React, { createContext, useContext, useState, useEffect } from "react";
import { formatPublicKey } from "../utils/formatKey";

const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [publicKey, setPublicKey] = useState({
    formatted: null,
    unformatted: null,
  });

  useEffect(() => {
    const wallet = window.solana;

    const handleConnection = (publicKey) => {
      const unformattedPublicKey = publicKey.toString();
      const formattedPublicKey = formatPublicKey(unformattedPublicKey);
      setIsConnected(true);
      setPublicKey({
        unformatted: unformattedPublicKey,
        formatted: formattedPublicKey,
      });
    };

    if (wallet && wallet.isPhantom) {
      setProvider(wallet);
      if (wallet.isConnected) {
        handleConnection(wallet.publicKey);
      }

      // Event listeners for connecting and disconnecting
      wallet.on("connect", handleConnection);
      wallet.on("disconnect", () => {
        setIsConnected(false);
        setPublicKey({ formatted: null, unformatted: null });
      });

      return () => {
        wallet.removeListener("connect", handleConnection);
        wallet.removeListener("disconnect");
      };
    } else {
      console.error("Phantom wallet not found or unsupported.");
    }
  }, []);

  const connectWallet = async () => {
    try {
      if (provider) {
        await provider.connect();
      } else {
        console.error("Wallet provider not found.");
      }
    } catch (error) {
      console.error("Failed to connect to the wallet:", error);
    }
  };

  return (
    <WalletContext.Provider
      value={{
        provider,
        isConnected,
        publicKey,
        connectWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);

export default WalletProvider;
